import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch, requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        // LOGIN
        async getSchemes({commit}) {
            const response = await requestFetch(`/api/v2/chemotherapy/scheme/all`, 'GET');
            return response.schemes;

        },
        async deleteSchemeStore({commit},id) {
            const response = await requestFetch(`/api/v2/chemotherapy/scheme_delete/${id}`, 'DELETE');
            if (response.msg){
                return true
            }else{
                return false
            }

        },
        async getSchemesPatient({commit}, id) {
            const response = await requestFetch(`/api/v2/chemotherapy/scheme_assign/all/${id}`, 'GET');
            return response.schemes;

        },
        async getSchemesPatientOne({commit}, {id, patient_id}) {
            const response = await requestFetch(`/api/v2/chemotherapy/scheme_assign/one/${id}/${patient_id}`, 'GET');
            return response.scheme;

        },
        async getSchemesOne({commit}, id) {
            const response = await requestFetch(`/api/v2/chemotherapy/scheme/one/${id}`, 'GET');
            return response.scheme;

        },
        async getReason({commit}) {
            const response = await requestFetch(`/api/v2/chemotherapy/reason`, 'GET');

            return response.reasons;

        },
        async getDose({commit}) {
            const response = await requestFetch(`/api/v2/chemotherapy/dose`, 'GET');

            return response.units_dose;

        },
        async getTimes({commit}) {
            const response = await requestFetch(`/api/v2/chemotherapy/time`, 'GET');

            return response.units_time;

        },
        async getVias({commit}) {
            const response = await requestFetch(`/api/v2/chemotherapy/via`, 'GET');

            return response.vias;

        },
        async createSchemeServer({commit}, data) {
            const response = await requestFetch(`/api/v2/chemotherapy/scheme/create`, 'POST', data);
            if (response.msg) {
                return true
            } else {
                return false
            }
        },
        async updateSchemeServer({commit}, {data,id}) {
            const response = await requestFetch(`/api/v2/chemotherapy/scheme_assign/${id}`, 'PUT', data);
            if (response.msg) {
                return true
            } else {
                return false
            }
        },
    },
    getters: {}

}
