import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch, requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        async findOne({commit},id) {
            const response = await requestFetch(`/api/v2/questionnaire/${id}`, 'GET');
            return response.question;
        },
        async create({commit},data) {
            const response = await requestFetch(`/api/v2/questionnaire`, 'POST',data);
            return response;
        },
        async updateQuestionRequest({commit}, {id, data}) {
            const response = await requestFetch(`/api/v2/questionnaire/${id}`, 'PUT',data);
            return response;
        },
        async deleteImgFile({commit}, {id, type}) {
            const response = await requestFetch(`/api/v2/questionnaire/delete/${type}/${id}`, 'GET');
            return response;
        },
        async downloadFiPatientOne({commit}, {id,type}) {

            const response = await requestFetch(`/api/v2/questionnaire/download/${type}/${id}`, 'GET');
            var a = document.createElement('a');
            a.href = response.msg;
            a.download = response.msg
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
    },
    getters: {
    }

}
