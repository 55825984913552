import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch, requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        // LOGIN
        async createName({commit},id) {
            const response = await requestFetch(`/api/v2/share/verify_bucket/${id}`, 'GET');
            return response.msg;
        },
        async shareEmail({commit},data) {
            const response = await requestFetch(`/api/v2/share/send_email`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async shareWhatsapp({commit},data) {
            const response = await requestFetch(`/api/v2/share/send_whatsapp`, 'POST', data);
            if (response.content){
                const res = {
                    status: true,
                    lines:response.content
                }
                return res
            }else{
                return false
            }
        },
        async shareCopy({commit},data) {
            const response = await requestFetch(`/api/v2/share/send_copy`, 'POST', data);
            if (response.lines){
                const res = {
                    status: true,
                    lines:response.lines
                }
                return res
            }else{
                return false
            }
        },
    },
    getters: {}

}
