import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findCountry({commit},data) {
            const response = await requestFetch(`/api/address/country/${data}`, 'GET');
            return response.countries;
        },
        async findCountryState({commit},data) {
            const response = await requestFetch(`/api/address/state/${data}`, 'GET');
            return response.states;
        },
        async findCountryCities({commit},data) {
            let country_id = null
            let state_id = null
            if (!data.state_id){
                country_id = data.country_id
                const response = await requestFetch(`/api/address/city/${country_id}`, 'GET');
                return response.cities;
            }else{
                country_id = data.country_id
                state_id = data.state_id
                const response = await requestFetch(`/api/address/city/${country_id}/${state_id}`, 'GET');
                return response.cities;
            }

        },
    },
    getters: {
    }

}
