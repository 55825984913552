import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch,requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        async findHistory({commit},data) {
            const response = await requestFetch(`/api/clinic_patient/history/${data}`, 'GET');
            return response.history;
        },
        async createHistory({commit},data) {
            const response = await requestFetch(`/api/clinic_patient/history`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async nameClinic({commit}) {
            const response = await requestFetch(`/api/clinic_patient/type`, 'GET');
            return response.types;

        },
        async findFiles({commit},data) {
            const response = await requestFetch(`/api/clinic_patient/files/${data.patient_id}/${data.file}`, 'GET');
            return response.files;
        },
        async createFiles({commit},data) {
            const response = await requestFetch(`/api/clinic_patient/create_files`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async downloadFile({ commit },data) {
            const response = await requestFetchFile(`/api/clinic_patient/download/${data.id}`,'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = data.name + '-' + data.name_clinic + '.'+data.type;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();

        },


    },
    getters: {
    }

}
